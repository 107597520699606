import { checkDirection, TDirection } from '@/utils/iconHelper'
import React from 'react'

export default function ExpandIcon({ direction = 'down' }: TDirection) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.992"
            height="8"
            viewBox="0 0 13.992 8"
            style={{
                rotate: `${checkDirection(direction)}deg`,
                transition: 'rotate 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
            }}
        >
            <path
                id="Icon_ionic-ios-arrow-forward"
                data-name="Icon ionic-ios-arrow-forward"
                d="M5.588,6.993.294,1.7a1,1,0,0,1,0-1.412,1.008,1.008,0,0,1,1.416,0l6,5.995a1,1,0,0,1,.029,1.379L1.714,13.7A1,1,0,0,1,.3,12.288Z"
                transform="translate(0 8) rotate(-90)"
                fill="#42526e"
            />
        </svg>
    )
}
