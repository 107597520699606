export interface TDirection {
    direction?: 'up' | 'down' | 'left' | 'right'
}

export const checkDirection = (direction: TDirection['direction']) => {
    switch (direction) {
        case 'up': {
            return 0
        }
        case 'down': {
            return 180
        }
        case 'left': {
            return 270
        }
        case 'right': {
            return 90
        }
        default: {
            return 0
        }
    }
}
